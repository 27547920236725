import {
  getClearingOfficeRef,
  getLottery,
  getMemberId,
  getOfferId,
  getRetailerId,
  getReward,
  getTriggerableGame,
  postLottery,
  postTriggerableOffers,
} from '../../../../utils/catalinaRequests';
import {
  isEcommerceMode,
  isPinMode,
  isReceiptMode,
} from '../../../../utils/clientConfig';

export async function getGameResult(setWinner, setAllowed, setRewardDesc) {
  //if e-commerce
  if (isEcommerceMode()) {
    await getTriggerableGame().catch((err) => {
      console.error(err);
      window.location.href = '/can-not-play';
    });
    let resp = await getReward(getOfferId()).catch((err) => {
      console.error(err);
      window.location.href = '/can-not-play';
    });
    let clearing_office_ref = getClearingOfficeRef();

    let isValid = !resp.filter(
      (e) => e.clearing_office_ref === clearing_office_ref,
    ).length;

    console.log('resp', JSON.stringify(resp, null, 2));

    if (!isValid) {
      console.log('isNotvalid');
      window.location.href = '/can-not-play';
    } else {
      await postTriggerableOffers().catch((err) => {
        console.error(err);
        window.location.href = '/can-not-play';
      });
    }
  } else if (isReceiptMode() || isPinMode()) {
    //if print ou receipt
    await postLottery(getMemberId(), getRetailerId(), getOfferId()).catch(
      (err) => {
        console.error('Error on Post Lottery', err);
        window.location.href = '/can-not-play';
      },
    );
  }
  //for pin, print & ecommerce
  await getLottery(getMemberId(), getRetailerId(), getOfferId()).then(
    (resp) => {
      setAllowed(true);
      sessionStorage.setItem('rewardId', resp.reward_id);
      if (resp.result === 'won') {
        setWinner(true);
      } else {
        setWinner(false);
      }
      if (resp?.prize?.description) {
        setRewardDesc(resp?.prize?.description);
      }
    },
  );
}
